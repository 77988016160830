<template>
  <div class="coberturaSocial">
      <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Cobertura Social</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Infraestructura, Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Cobertura Social 2021-2022</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/PCS_2021-2022.pdf" download="PCS_2021-2022.pdf">Programa de Cobertura Social 2021-2022</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/2022_PCS_Anexo_1.xlsx" download="2022_PCS_Anexo_1.xlsx">Anexo 1</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/2022_PCS_Anexo_2.xlsx" download="2022_PCS_Anexo_2.xlsx">Anexo 2</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/2022_PCS_Anexo_3.xlsx" download="2022_PCS_Anexo_3.xlsx">Anexo 3</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href = '/cobertura-socialMenu'>Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"
export default {
  name: 'coberturaSocial',
   methods: {
    back(){
        router.replace('/')
    },
  },
}
</script>

